<template>
  <div style="background: #F7F9FC;color: #000;height: 100%;">
    <van-nav-bar title="图册" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <div class="hed_lis">
      <div class="hhcc" @click="qiehuan(0)">
        门店环境
        <div class="hua" v-if="active == '0'"></div>
      </div>
      <div class="hhcc" @click="qiehuan(1)">
        门店资质
        <div class="hua" v-if="active == '1'"></div>
      </div>
      <div class="hhcc" @click="qiehuan(2)">
        门店设备
        <div class="hua" v-if="active == '2'"></div>
      </div>
      <div class="hhcc" @click="qiehuan(3)">
        门店项目
        <div class="hua" v-if="active == '3'"></div>
      </div>
    </div>
    <div style="margin-top:93px;height: 100%;background: rgb(235, 237, 240)">
      <div class="list">
        <div class="list_img" v-for="(item,idx) in list" :key="idx">
          <img class="c_img" :src="item" @click="tupian(item)" alt />
        </div>
      </div>
      <van-empty v-if="list.length < 1" description="暂无数据~" />
    </div>
  </div>
</template>

<script>
import shopModel from "../../api/shop";
import { ImagePreview } from "vant";
export default {
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  data() {
    return {
      list: [],
      gid: this.$route.query.gid,
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      active: this.$route.query.active
    };
  },
  methods: {
    qiehuan(row) {
      this.active = row;
      this.onLoad();
    },

    onLoad() {
      const data = {
        gid: this.gid,
        type: this.active
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      shopModel
        .pic(data)
        .then(e => {
          loading.clear();
          this.list = e.data;
        })
        .catch(() => loading.clear());
    },
    tupian(item) {
      // ImagePreview({[{item}],closeable: true,});
      ImagePreview({
        images: [item],
        closeable: true
      });
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.hed_lis {
  height: 45px;
  background: #fff;
  border-bottom: 1px solid #ebedf0;
  position: fixed;
  top: 46px;
  width: 100%;
  z-index: 99;
}
.hhcc {
  float: left;
  font-size: 14px;
  color: #646566;
  width: 25%;
  text-align: center;
  line-height: 45px;
  position: relative;
  overflow: hidden;
}
.hua {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-bottom: 5px;
  width: 46px;
  text-align: center;
  margin-left: -23px;
  height: 3px;
  z-index: 1;
  background-color: #ee0a24;
  border-radius: 1.5px;
}
.bot {
  width: 100%;
  overflow: hidden;
  padding-bottom: 10px;
}
.list {
  padding: 20px 25px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.list_img {
  float: left;
  width: 47%;
}
.c_img {
  width: 100%;
  height: 120px;
  //   width: 45%;
}
.list_img:nth-child(2n) {
  float: right;
}
</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}
</style>
